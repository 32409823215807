/* Tailwind CSS 기본 스타일 import */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* 사용자 정의 스타일 */

/* 기본 폰트 설정 */
body {
  font-family: 'Noto Sans KR', sans-serif;
}

/* 캐주얼 폰트 클래스 */
.font-casual {
  font-family: 'Gaegu', cursive;
}

/* 앱 컨테이너 스타일 */
.app-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

/* 캔버스 터치 동작 비활성화 (모바일에서 스크롤 방지) */
canvas {
  touch-action: none;
}

/* 버튼 스타일 및 호버 효과 */
.btn {
  transition: all 0.3s ease;
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* 펄스 애니메이션 정의 */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.animate-pulse {
  animation: pulse 1s infinite;
}